import { Box, Spinner } from "@chakra-ui/react";

export function FullLoading() {
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      opacity="0.8"
    >
      <Spinner
        color="green.600"
        emptyColor="white"
        thickness="4px"
        size="xl"
        h={100}
        w={100}
      />
    </Box>
  );
}
