import { Box, Link, Text } from "@chakra-ui/react";

export function NotFound() {
  return (
    <Box>
      <Text>
        Página não encontrada. <Link href="/home">Voltar para home</Link>
      </Text>
    </Box>
  );
}
